/* Universal CSS */
:root {
    --bg: #17181F;
    --bg-accent: #252632;
    overflow-y: scroll;
    -webkit-tap-highlight-color: transparent;
}

body{
    background-color: var(--bg);
}

.GenericLinkDeco{
    text-decoration: none;
    cursor: pointer;
    height: fit-content;
    width: fit-content;
}

/* ScrollBar */
/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;  
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c3b1e185;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ad9dc7;
}

/* Media Query Phones */
@media only screen and (max-device-width: 435px) {
    ::-webkit-scrollbar {
        width: 4px;
        height: 0px;  
    }
}