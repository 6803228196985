/* Nav Bar */
.navBar{
    background-color: var(--bg-accent);
    height: 65px;
    font-family: 'Inter', sans-serif;
    display: flex;
    column-gap: 540px;
    user-select: none;
}

.navBarContainer{
    display: flex;
    column-gap: 540px;
    margin-left: auto;
    margin-right: auto;
}

/* Nav Bar Home */
.navHomeMainText{
    color: white;
    padding-top: 19px;
    font-weight:550;
    font-size: 20px;
    cursor: pointer;
    height: fit-content;
}

.navHomeImg{
    padding-top: 15px;
    height: fit-content;
}

.navHome{
    display: flex;
    column-gap: 12px;
}

/* Nav Bar Buttons */
.navButtonContainer{
    padding-top: 15px;
}

.navButtonButtons{
    display: flex;
    column-gap: 10px;
}

.navButton{
    background-color: #363742;
    font-size: 14px;
    font-weight: 500;
    height: fit-content;
    color: white;
    border: transparent;
    border-width: 2px;
    border-style: solid;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 20px;
    transition: 0.1s;
    opacity: 1;
}

.dropdownIcon{
    margin-left: 0.5px;
    transition: 120ms ease;
}

.dropdownIcon#dropdownIcon_inactive{
    transform: rotate(180deg) scale(1.2);
}

.dropdownIcon#dropdownIcon_active{
    transform: scale(1.2);
    padding-bottom: 1px;
    margin-top: -1px;
}

.navButton#clicked{
    opacity: 0.5;
}

.navButton#clicked:hover{
    cursor:not-allowed;
    background-color: #363742;
}

.navButton:hover{
    background-color: #484953;
    cursor: pointer;
    opacity: 1;
}

.NavLinkExtra{
    color: white;
    text-decoration: none;
    cursor: pointer;
    height: fit-content;
    width: fit-content;
}

.NavLinkHomeExtra{
    text-decoration: none;
    height: fit-content;
}

.navButton#menuButton_active{
    opacity: 0.5;
    padding-bottom: 0px;
    padding-top: 4.7px;
    padding-left: 5px;
    padding-right: 5px;
}

.navButton#menuButton_inactive{
    opacity: 1;
    padding-bottom: 0px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

/* Drop Down Menu */
.dropDownMenu{
    padding-left: 30px;
    background-color: #343646;
    border: rgba(255, 255, 255, 0);
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    margin-top: 10px;
    margin-left: -30px;
    padding-right: 10px;
    z-index: 10;
    position: absolute;
}

.dropDownMenu.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 100ms ease;
}

.dropDownMenu.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-9px);
    transition: 100ms ease;
}

.NavBarItem{
    width: 154px;
    margin-left: -21px;
    border-radius: 8px;
    transition: 0.1s;
}

.NavBarItem:hover{
    background-color: rgba(75, 77, 98, 0.569)
}

.NavBarTitle{
    text-align: center;
    padding-top: 9px;
    padding-bottom: 9px;
    color: white;
    font-size: 15px;
}

.navBarSpacerTop{
    margin-top: 6.5px;
}

.navBarSpacerBottom{
    margin-bottom: 6.5px;
}

.MenuIcon{
    color:white;
}

.NavBarInfoContainer{
    display: flex;
    column-gap: 8px;
}

.NavBarIconList{
    padding-top: 10px;
    padding-left: 6px;
}

.DropdownHR hr {
    border-top: 1px solid rgba(255, 255, 255, 0.015);
    margin-left: -20px;
}

/* Media Query Phones */
@media only screen and (max-device-width: 435px) {
    .navBarContainer{
        column-gap: 31vw;
    }
    .navHome{
        padding-top: 1px;
        transform: scale(0.98);
    }
}


